<template>
  <BModal
    :id="nombreModal"
    :title="title"
    size="md"
    centered
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    :hide-footer="true"
    :ok-title="title"
    :ok-disabled="this.v$.asignatura.$errors.length > 0"
    @close="closeModal"
    @ok.prevent="submitOption"
  >
    <BOverlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form>
        <!-- ASIGNATURAS FORM -->
        <BRow>

          <!-- col: CURSO -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Curso"
              label-for="curso"
            >
              <BFormInput
                id="curso"
                :value="`${selectedCurso.nombre} ${selectedCurso.letra}`"
                :disabled="true"
              />
            </BFormGroup>
          </BCol>

          <!-- col: NOMBRE -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Nombre *"
              label-for="nombre"
            >
              <BFormInput
                id="nombre"
                v-model="asignatura.nombre"
                placeholder="Ingrese el nombre"
                :state="v$.asignatura.nombre.$error === true
                  ? false
                  : null"
                @blur.native="v$.asignatura.nombre.$touch"
              />
              <BFormInvalidFeedback
                v-if="v$.asignatura.nombre.$error"
                id="nombreInfo"
                class="text-right"
              >
                <p v-for="error of v$.asignatura.nombre.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- col: TIPO -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Tipo de asignatura *"
              label-for="tipo"
            >
              <BFormSelect
                v-model="asignatura.tipo"
                :options="getTiposAsignatura"
                id="forma"
                placeholder="Seleccione un tipo de asignatura..."
                :class="{'border-danger rounded': v$.asignatura.tipo.$error}"
              >
                <template #first>
                  <option disabled value="null">{{ 'Seleccione un tipo de asignatura ...' }}</option>
                </template>
              </BFormSelect>
              <div
                v-if="v$.asignatura.tipo.$error"
                id="tipoInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.asignatura.tipo.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- col: HORAS -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Horas semanales *"
              label-for="horas"
            >
              <cleave
                id="horas"
                v-model="asignatura.horas"
                class="form-control"
                :class="{'is-invalid': !isValid}"
                :raw="false"
                :options="options.horas"
                placeholder="Ingrese el horas pedagógicas semanales"
                @input="asignatura.horas = formatHorasPedagogicasSemanales(asignatura.horas)"
                @blur="asignatura.horas = formatHorasPedagogicasSemanalesBlur(asignatura.horas)"
              />
              <BFormInvalidFeedback
                v-if="v$.asignatura.horas.$error"
                id="horasInfo"
                class="text-right"
              >
                <p v-for="error of v$.asignatura.horas.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

        </BRow>

        <colLinea />
        <div class="text-right">
          <!-- Action Buttons -->
          <BButton
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </BButton>

          <BButton
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ title }}
          </BButton>
        </div>
      </b-form>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BButton, BOverlay, BModal, VBModal, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapState } from 'vuex'

// CLEAVE
import Cleave from 'vue-cleave-component'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../components/Form/colLinea.vue'
import btnSubmit from '../../../components/Form/btnSubmit.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
    BButton, BOverlay, BModal, VBModal, BFormSelect,
    vSelect,
    Cleave,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      options: {
        horas: {
          blocks: [1, 1],
          delimiter: '.',
          numericOnly: true,
          // delimiterLazyShow: true,
          // numericPositiveOnly: true,
        }
      },
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
    ...mapGetters({
      getTiposAsignatura: 'asignaturas/getTiposAsignatura',
    }),
    isValid() {
      return !this.v$.asignatura.horas.$error
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    asignatura: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitOption() {
      if (this.asignatura.horas === '0.0') {
        this.asignatura.horas = ''
      }
      this.v$.asignatura.$touch()
      if (!this.v$.asignatura.$invalid) {
        this.$emit('processForm', this.asignatura)
        this.v$.asignatura.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.asignatura.$reset()
      this.$emit('resetData')
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      asignatura: {
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 80 caracteres.', maxLength(80)),
        },
        tipo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        horas: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
