<template>
  <div v-if="$can('create', modulo)" class="text-right" >
    <BButton
      variant="primary"
      @click="btnSubmit"
    >
      <span class="text-nowrap">{{ accion }} {{ texto }}</span>
    </BButton>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    texto: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    accion: {
      tyle: String,
      default: 'Crear',
    },
    modal: {
      tyle: String,
      default: true,
    }
  },
  methods: {
    btnSubmit() {
      this.$bvModal.show(this.modal)
    },
  },
}
</script>
