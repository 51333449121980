<template>
  <BModal
    :id="nombreModal"
    title="Configuraciones de asignatura"
    size="md"
    centered
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    :hide-footer="true"
    :ok-title="configs.title"
    :ok-disabled="this.v$.dataForm.$errors.length > 0"
    @shown="onModalOpen"
    @close="closeModal"
    @ok.prevent="submitOption"
  >
    <BOverlay
      :show="getLoading || configs.loading"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form>

        <!-- ALERT -->
        <BRow v-if="configs.alert">
          <BCol>
            <b-alert
              variant="info"
              show
              class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
            >
              {{ configs.alert }}
            </b-alert>
          </BCol>
        </BRow>

        <!-- CONFIGURACIONES ASIGNATURAS FORM -->
        <BRow v-else>

          <!-- ASIGNATURA ORIGEN -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Asignatura origen"
              label-for="id_asignatura"
            >
              <BFormInput
                id="id_asignatura"
                :value="asignatura.nombre"
                :disabled="true"
              />
            </BFormGroup>
          </BCol>

          <!-- col: FORMA -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Método de Traspaso de Notas *"
              label-for="forma"
            >
              <BFormSelect
                v-model="dataForm.forma"
                :options="optionsFormas"
                id="forma"
                placeholder="Seleccione un método ..."
                :class="{'border-danger rounded': v$.dataForm.forma.$error}"
              >
                <template #first>
                  <option disabled value="null">{{ 'Seleccione un método ...' }}</option>
                </template>
              </BFormSelect>
              <div
                v-if="v$.dataForm.forma.$error"
                id="tipoInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.dataForm.forma.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- ASIGNATURA DESTINO -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Asignatura destinio *"
              label-for="id_asignatura_destino"
            >
              <BFormSelect
                v-model="dataForm.id_asignatura_destino"
                :options="optionsAsignaturasDestino"
                id="id_asignatura_destino"
                placeholder="Seleccione una asignatura ..."
                :class="{'border-danger rounded': v$.dataForm.id_asignatura_destino.$error}"
              >
                <template #first>
                  <option disabled value="null">{{ 'Seleccione una asignatura ...' }}</option>
                </template>
              </BFormSelect>
              <div
                v-if="v$.dataForm.id_asignatura_destino.$error"
                id="tipoInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.dataForm.id_asignatura_destino.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

        </BRow>

        <colLinea />

        <!-- Action Buttons -->
        <div class="text-right">
          <BButton
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </BButton>

          <BButton
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ configs.textBtn }}
          </BButton>
        </div>
      </b-form>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BOverlay,
  BModal, VBModal, BFormSelect, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapActions, mapState, mapGetters} from 'vuex'


// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../components/Form/colLinea.vue'
import btnSubmit from '../../../components/Form/btnSubmit.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BOverlay,
    BModal, VBModal, BFormSelect, BAlert,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  data() {
    return {
      configs: {
        loading: true,
        title: '',
        alert: '',
        textBtn: 'Guardar',
      },
      dataForm: {
        forma: 2,
        id_asignatura: null,
        id_asignatura_destino: null,
        id_estado: null,
      },
      optionsFormas: [
        {
          value: 2,
          text: 'Traspasar promedios',
        },
        {
          value: 1,
          text: 'Traspasar notas',
        },
      ],
      optionsAsignaturasDestino: [],
    }
  },
  computed: {
    ...mapState('asignaturas', ['asignaturasCurso']),
    ...mapState('asignaturasConfig', ['asignaturaConfig', 'response']),
    ...mapState('cursos', ['selectedCurso']),
    ...mapGetters({ getLoading: 'asignaturasConfig/getLoading' }),
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    asignatura: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchAsignaturaConfig: 'asignaturasConfig/fetchAsignaturaConfig',
      updateAsignaturaConfig: 'asignaturasConfig/updateAsignaturaConfig',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    setDataForm(id_asignatura) {
      this.fetchAsignaturaConfig(id_asignatura).then(() => {
        if (!this.asignaturaConfig.status) {
          this.dataForm = this.asignaturaConfig
          this.configs.textBtn = 'Editar'
        } else {
          this.configs.textBtn = 'Crear'
        }
      })
    },
    setAsignaturaDestino(asignatura) {
      this.configs.alert = ''
      if (asignatura.tipo === 2) { // pertenece a compuesta
        const asignaturasCompuestas = this.asignaturasCurso.filter(ac => ac.tipo === 5)
        if (asignaturasCompuestas.length === 0) {
          this.configs.alert = 'Primero debe crear una asignatura compueta.'
        } else {
          this.setOptionsAsignaturaDestino(asignaturasCompuestas)
        }
      }
      if (asignatura.tipo === 3) { // taller
        const asignaturas = this.asignaturasCurso.filter(ac => ac.tipo !== 4 && ac.tipo !== 3)
        if (asignaturas.length === 0) {
          this.configs.alert = 'No existen asignaturas de destino para el taller (tipos: normal, compuestas o pertenece a compuesta).'
        } else {
          this.setOptionsAsignaturaDestino(asignaturas)
        }
      }
    },
    setOptionsAsignaturaDestino(asignaturas) {
      this.optionsAsignaturasDestino = []
      asignaturas.forEach(asignatura => {
        this.optionsAsignaturasDestino.push({
          value: asignatura.id,
          text: asignatura.nombre,
        })
      })
      if (this.optionsAsignaturasDestino.length === 1) {
        this.dataForm.id_asignatura_destino = asignaturas[0].id
      }
      this.configs.loading = false
    },
    submitOption() {
      this.v$.dataForm.$touch()
      if (!this.v$.dataForm.$invalid) {
        this.v$.dataForm.$reset()
        this.dataForm.id_asignatura = this.asignatura.id
        this.dataForm.id_estado = '1'
        this.updateAsignaturaConfig(this.dataForm).then(() => {
          this.sweetMensaje(!this.dataForm.id ? 'c' : 'u')
          this.fetchAsignaturasCurso(this.selectedCurso.id)
        })
      }
    },
    sweetMensaje(tipo) {
      if (this.response.status === 'success') {
        const text = tipo === 'c'
          ? 'ingresada'
          : 'editada'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Configuración guardada 👍',
              icon: 'CheckIcon',
              text: `La configuración fue ${text} con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })

          this.$bvModal.hide(this.nombreModal)
          this.initData()
        } else {
          this.$swal({
            title: 'Error!',
            text: this.response.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
    },
    initData() {
      this.dataForm.forma = 2
      this.dataForm.id_asignatura = null
      this.dataForm.id_asignatura_destino = null
      this.dataForm.id_estado = null
    },
    onModalOpen() {
      this.setDataForm(this.asignatura.id)
      this.setAsignaturaDestino(this.asignatura)
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.dataForm.$reset()
      this.initData()
    },

  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      dataForm: {
        forma: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_asignatura_destino: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
}
</script>
